import isEqual from 'lodash/isEqual';
import { polygon as turfPolygon, featureCollection } from '@turf/helpers';
import { intersect } from '@turf/intersect';
import { kinks } from '@turf/kinks';

import type { Geometry, GeometryInput } from 'src/types/__generated__/graphql';
import { GeometryType } from 'src/types/__generated__/graphql';

type Position = number[];

export const transformGoogleMapBoundsToGoogleMapPolygon = (
    bounds: google.maps.LatLngBounds,
    polygonOptions: google.maps.PolygonOptions = {},
) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const se = new google.maps.LatLng(sw.lat(), ne.lng());
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());

    const boxCoordinates = [
        { lat: nw.lat(), lng: nw.lng() },
        { lat: ne.lat(), lng: ne.lng() },
        { lat: se.lat(), lng: se.lng() },
        { lat: sw.lat(), lng: nw.lng() },
        { lat: nw.lat(), lng: nw.lng() },
    ];
    return new google.maps.Polygon({
        ...polygonOptions,
        paths: boxCoordinates,
    });
};

export const fitMapToFrancePosition = (map: google.maps.Map) => {
    const franceViewport = {
        northeast: {
            lat: 51.3682119,
            lng: 11.4548613,
        },
        southwest: {
            lat: 40.8531652,
            lng: -6.272655599999999,
        },
    };
    const se = new google.maps.LatLng(franceViewport.southwest.lat, franceViewport.northeast.lng);
    const nw = new google.maps.LatLng(franceViewport.northeast.lat, franceViewport.southwest.lng);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(se);
    bounds.extend(nw);
    map.fitBounds(bounds, 0); // no padding
};

export const transformGoogleMapBoundsToGeometry = (bounds: google.maps.LatLngBounds): Geometry => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const se = new google.maps.LatLng(sw.lat(), ne.lng());
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    return {
        type: GeometryType.Polygon,
        coordinates: [
            [
                [nw.lng(), nw.lat()],
                [ne.lng(), ne.lat()],
                [se.lng(), se.lat()],
                [sw.lng(), sw.lat()],
                [nw.lng(), nw.lat()],
            ],
        ],
    };
};

export const transformGoogleMapPolygonToGeometry = (polygon: google.maps.Polygon): Geometry => {
    const pathArray = polygon.getPath().getArray();
    const coordinates = pathArray.map((marker) => [marker.lng(), marker.lat()]);
    if (!isEqual(coordinates[0], coordinates[coordinates.length - 1])) {
        coordinates.push([pathArray[0].lng(), pathArray[0].lat()]);
    }
    return {
        type: GeometryType.Polygon,
        coordinates: [coordinates],
    };
};

export const transformGoogleMapBoundsToGeoJSONBBox = (bounds: google.maps.LatLngBounds): GeoJSON.BBox => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const se = new google.maps.LatLng(sw.lat(), ne.lng());
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    return [nw.lng(), se.lat(), se.lng(), nw.lat()];
};

export const reestablishMapFromJSONLiteral = (map: google.maps.Map, jsonBounds: google.maps.LatLngBoundsLiteral) => {
    map.fitBounds(jsonBounds, 0);
};

export const fitMapToWoopenGeometry = (map: google.maps.Map, geometry: Geometry, padding = 0) => {
    // this function attempts to fit the map around a set of points
    // but a given set of point may have multiple valid bounding boxes
    // if you try to reestablish a bounding box from a polygon it may not be right
    // this happens especially when the correct polygon is wider than 1/2 the earth east-west

    const coordinates = geometry.coordinates as number[][][];
    const paths = coordinates[0]
        .map(([lng, lat]) => Boolean(lat && lng) && new google.maps.LatLng({ lat, lng }))
        .filter(Boolean);
    const correspondingPolygon = new google.maps.Polygon({
        paths: paths as unknown as google.maps.LatLng[],
    });
    const pathArray = correspondingPolygon.getPath().getArray();
    const bounds = new google.maps.LatLngBounds();
    pathArray.forEach((marker) => bounds.extend(marker));
    map.fitBounds(bounds, padding);
};

export const geocodeByPlaceId = async (placeId: string): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({ placeId }).then(({ results }) => results);
};

export const geocodeByAddress = async (address: string): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({ address }).then(({ results }) => results);
};

/**
 * Converts a geometry input to a Google Maps polygon.
 */
const transformGeometryInputToGoogleMapPolygon = (geometry: GeometryInput[]): google.maps.Polygon => {
    const coordinates = geometry[0].coordinates as number[][][];
    const gMapCoordinates = coordinates[0].map(([lng, lat]) => new google.maps.LatLng(lat, lng));
    return new google.maps.Polygon({ paths: gMapCoordinates });
};

/**
 * Gets the intersection geometries between two geometry inputs.
 */
export const getIntersectionGeometriesFromGeometryInputs = (
    geometry1: GeometryInput[],
    geometry2: GeometryInput[],
): Geometry[] | undefined => {
    const geometry1Polygon = transformGeometryInputToGoogleMapPolygon(geometry1);
    const geometry2Polygon = transformGeometryInputToGoogleMapPolygon(geometry2);
    const intersectionPolygons = getIntersectionPolygons(geometry1Polygon, geometry2Polygon);
    if (!intersectionPolygons?.length) {
        return undefined;
    }
    const intersectionPolygonGeometries = intersectionPolygons.map(transformGoogleMapPolygonToGeometry);
    return intersectionPolygonGeometries;
};

/**
 * Checks if a Google Maps polygon is self-intersecting.
 */
export const isPolygonSelfIntersecting = (polygon: google.maps.Polygon) => {
    const turfPolyg = googleMapPolygonToTurfPolygon(polygon);
    return kinks(turfPolyg).features.length > 0;
};

export const turfGeometryToTurfPolygons = (geometry: {
    type: 'Polygon' | 'MultiPolygon';
    coordinates: Position[][] | Position[][][];
}) => {
    let polygons: Array<ReturnType<typeof turfPolygon>>;

    if (geometry.type === 'Polygon') {
        polygons = [turfPolygon(geometry.coordinates as Position[][])];
    } else {
        polygons = (geometry.coordinates as Position[][][]).map((coords) => turfPolygon(coords));
    }

    return polygons;
};

/**
 * Creates the intersection polygons between two Google Maps polygons.
 * If the polygons don't intersect, it will return undefined.
 */
const getIntersectionPolygons = (
    originalPolygon: google.maps.Polygon,
    limitPolygon: google.maps.Polygon,
): google.maps.Polygon[] | undefined => {
    const turfOriginalPolygon = googleMapPolygonToTurfPolygon(originalPolygon);
    const turfLimitPolygon = googleMapPolygonToTurfPolygon(limitPolygon);

    const intersection = intersect(featureCollection([turfOriginalPolygon, turfLimitPolygon]));
    if (!intersection) {
        return undefined;
    }

    try {
        const turfPolygons = turfGeometryToTurfPolygons(intersection.geometry);
        const googleMapPolygons = turfPolygons
            .map(turfPolygonToGoogleMapPolygon)
            .filter(Boolean) as google.maps.Polygon[];
        return googleMapPolygons;
    } catch (e: unknown) {
        console.error('Error while creating intersection polygon', e);
        return undefined;
    }
};

/**
 * Converts a Turf polygon to a Google Maps polygon.
 */
const turfPolygonToGoogleMapPolygon = (turfPolyg: ReturnType<typeof turfPolygon>): google.maps.Polygon | undefined => {
    const polygonCoordinates = turfPolyg.geometry.coordinates;
    const gMapCoords = polygonCoordinates[0].map((coord) => new google.maps.LatLng(coord[1], coord[0]));
    if (!gMapCoords.length) {
        return undefined;
    }
    return new google.maps.Polygon({ paths: gMapCoords });
};

/**
 * Converts a Google Maps polygon to a Turf polygon.
 */
const googleMapPolygonToTurfPolygon = (gmapPolygon: google.maps.Polygon) => {
    const path = gmapPolygon.getPath();

    const coordinates = path.getArray().map((coord) => [coord.lng(), coord.lat()]);
    coordinates.push(coordinates[0]);

    return turfPolygon([coordinates]);
};
