/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect, useState, useMemo } from 'react';
import { useField } from 'formik';
import debounce from 'lodash/debounce';
import Autocomplete from '@mui/material/Autocomplete';
import type { AutocompleteInputChangeReason, AutocompleteProps, GridProps } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { useTranslation } from 'next-i18next';
import { useJsApiLoader } from '@react-google-maps/api';

import type { Props as TextFieldProps } from 'src/components/ui/TextField';
import TextField from 'src/components/ui/TextField';
import Icon from 'src/components/ui/Icon';
import { frAndDomTomCountries, googleApiLoaderParams } from 'src/constants/googleMap';

import { colours } from 'src/constants/colours';
import { styledComponent } from 'src/utils/styled';
import { geocodeByPlaceId, transformGoogleMapBoundsToGeometry } from 'src/utils/googleMapUtils';
import type { AddressParam, Geometry } from 'src/types/__generated__/graphql';

const StyledAutocomplete = styledComponent(Autocomplete)<{
    $heightVariant: Props['heightVariant'];
}>`
    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
        padding-top: 0;
    }
    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
        padding-top: ${({ $heightVariant }) => ($heightVariant === 'medium' ? '1.4rem' : '0.5rem')};
        padding-bottom: 0.6rem;
        padding-left: 0.45rem;
    }
    &.Mui-focused {
        .MuiInputLabel-root {
            ${({ $heightVariant }) =>
                $heightVariant === 'medium' && 'transform: translate(0.75rem, 0.5rem) scale(0.75);'}
            ${({ $heightVariant }) => $heightVariant === 'small' && 'display: none;'}
        }
    }
    .MuiInputLabel-root {
        ${({ $heightVariant }) =>
            $heightVariant === 'small' && 'transform: translate(0.5rem, 0.5rem) scale(1)!important;'}
    }
    .MuiFormLabel-filled {
        ${({ $heightVariant }) => $heightVariant === 'small' && 'display: none;'}
    }
`;

// https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=fr#types
type GoogleTypesCollection = '(cities)' | '(regions)' | 'geocode' | 'address' | 'establishment';

type AddressParamWithGeometry = Partial<AddressParam & { geometry?: Geometry }>;

type Props = {
    name: string;
    label?: string;
    helperText?: string;
    placeholder?: string;
    error?: string;
    InputProps?: TextFieldProps['InputProps'];
    InputLabelProps?: TextFieldProps['InputLabelProps'];
    disabled?: boolean;
    onlyFrance?: boolean;
    typesCollection?: GoogleTypesCollection;
    includeGeometry?: boolean;
    onChangeOption?: (value: AddressParamWithGeometry | null) => void;
    heightVariant?: 'small' | 'medium';
};

const GooglePlacesAutocomplete: React.FC<
    Props & Partial<AutocompleteProps<unknown, boolean | undefined, boolean | undefined, boolean | undefined, 'div'>>
> = ({
    name,
    label,
    helperText,
    placeholder,
    error: propsError,
    InputProps,
    InputLabelProps,
    disabled,
    onlyFrance,
    typesCollection,
    includeGeometry = false,
    onChangeOption,
    heightVariant = 'medium',
    ...rest
}) => {
    const { t } = useTranslation(['common']);
    const [autocompleteService, setAutocompleteService] = useState<
        google.maps.places.AutocompleteService | undefined
    >();
    const [field, meta, { setValue }] = useField<AddressParamWithGeometry | null>({
        name,
    });
    const [inputField, _metaInputField, { setValue: setInputFieldValue }] = useField<string>({
        name: 'googlePlacesAutocompleteSearch',
        value: field.value?.description ?? '',
    });
    const [selectedOption, setSelectedOption] = useState<google.maps.places.AutocompletePrediction | null>(
        field.value
            ? ({
                  place_id: field?.value.googlePlaceId,
                  description: field?.value.description,
              } as google.maps.places.AutocompletePrediction)
            : null,
    );

    const formikError = meta.touched && meta.error ? meta.error : undefined;
    const [addressError, setAddressError] = useState<string | undefined>();
    const error = propsError ?? formikError ?? addressError ?? undefined;
    const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const { isLoaded: googleApiIsLoaded } = useJsApiLoader(googleApiLoaderParams);

    useEffect(() => {
        if (googleApiIsLoaded && !autocompleteService) {
            setAutocompleteService(new google.maps.places.AutocompleteService());
        }
    }, [googleApiIsLoaded, autocompleteService]);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly google.maps.places.AutocompletePrediction[] | null) => void,
                ) => {
                    if (autocompleteService) {
                        autocompleteService.getPlacePredictions(
                            {
                                ...request,
                                types: typesCollection ? [typesCollection] : undefined,
                                componentRestrictions: onlyFrance
                                    ? {
                                          country: frAndDomTomCountries,
                                      }
                                    : undefined,
                            },
                            callback,
                        );
                    }
                },
                400,
            ),
        [typesCollection, onlyFrance, autocompleteService],
    );

    const formatAddressInformations = (address: google.maps.GeocoderResult) => {
        const fullAddress: AddressParamWithGeometry = {
            description: address.formatted_address,
            googlePlaceId: address.place_id,
            lat: address.geometry.location.lat(),
            lng: address.geometry.location.lng(),
            zipCode: undefined,
            country: undefined,
            cityName: undefined,
        };

        address.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
            if (component.types.includes('locality')) {
                fullAddress.cityName = component.long_name;
            }
            if (component.types.includes('postal_code')) {
                fullAddress.zipCode = component.long_name;
            }
            if (component.types.includes('country')) {
                fullAddress.country = component.long_name;
            }
        });
        return fullAddress;
    };

    useEffect(() => {
        const noSelectedOptionButFieldValue = Boolean(field.value?.description && !selectedOption);
        const placeIdDifferentBetweenFieldAndSelectedOption = Boolean(
            field.value?.googlePlaceId &&
                selectedOption?.place_id &&
                field.value.googlePlaceId !== selectedOption.place_id,
        );
        // if field values are updated from the parent
        if (!field.value) {
            setSelectedOption(null);
            setOptions([]);
            onChangeOption?.(null);
        } else if (noSelectedOptionButFieldValue || placeIdDifferentBetweenFieldAndSelectedOption) {
            const newOption = {
                description: field.value?.description ?? '',
                place_id: field.value?.googlePlaceId,
            } as google.maps.places.AutocompletePrediction;
            setSelectedOption(newOption);
            setOptions([newOption]);
            onChangeOption?.(newOption);
        }
        setInputFieldValue(field.value?.description ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    useEffect(() => {
        if (field.value?.description && !field.value.googlePlaceId && autocompleteService) {
            // this is used in the case we have a description but not all data needed (for create company from PJ for example)
            fetch(
                { input: field.value.description },
                (results?: readonly google.maps.places.AutocompletePrediction[] | null) => {
                    let newOptions = [] as google.maps.places.AutocompletePrediction[];
                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                    setSelectedOption(newOptions[0]);
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value, autocompleteService]);

    useEffect(() => {
        const getAddressInformation = async (googlePlaceId: string) => {
            try {
                const geocodeResults = await geocodeByPlaceId(googlePlaceId);
                const fullAddress = formatAddressInformations(geocodeResults[0]);
                const newValue = fullAddress;
                if (includeGeometry) {
                    const addressGeometry = transformGoogleMapBoundsToGeometry(geocodeResults[0].geometry.viewport);
                    newValue.geometry = addressGeometry;
                }
                setValue(newValue);
                onChangeOption?.(newValue);
            } catch (err: unknown) {
                console.error(err);
                setValue(null);
            }
        };
        const exactStreetAddress = selectedOption?.types?.includes('street_address');
        const exactRouteAddress = selectedOption?.types?.includes('route');
        const exactPremise = selectedOption?.types?.includes('premise');
        const exactSubPremise = selectedOption?.types?.includes('subpremise');
        const exactAddress =
            Boolean(exactStreetAddress) ||
            Boolean(exactPremise) ||
            Boolean(exactRouteAddress) ||
            Boolean(exactSubPremise);
        let isValidAddress = true;
        switch (typesCollection) {
            case '(cities)':
                isValidAddress = Boolean(selectedOption?.types?.includes('locality'));
                break;
            case 'address':
                isValidAddress = exactAddress;
                break;
            default:
                break;
        }
        if (selectedOption?.types && selectedOption.types.length > 0 && !isValidAddress) {
            // type street_address => the one with street number and route
            // possible others interesting type : point_of_interest, establishment, post_box, park
            setAddressError(t('common:errors.validAddress'));
            setOptions([selectedOption]);
        } else if (
            selectedOption?.place_id &&
            (!field.value?.googlePlaceId || field.value.googlePlaceId !== selectedOption.place_id)
        ) {
            getAddressInformation(selectedOption.place_id);
            setAddressError(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const handleChange = (item: google.maps.places.AutocompletePrediction | null, reason: string) => {
        const newValueNotInOptions = !options.find((option) => option.place_id === item?.place_id);
        setOptions(newValueNotInOptions && item ? [item, ...options] : options);
        setSelectedOption(item);
        setInputFieldValue(item?.description ?? '');
    };

    const handleSearchInputChange = (
        _ev: React.SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason,
    ) => {
        // reason = clear => when clear the input
        // reason = reset => when select dropdown value
        // reason = input => when writing in the input
        if (reason === 'clear' || (reason === 'input' && value.length === 0)) {
            setValue(null);
            onChangeOption?.(null);
            setSelectedOption(null);
            setOptions([]);
        }

        if (value.length) {
            fetch({ input: value }, (results?: readonly google.maps.places.AutocompletePrediction[] | null) => {
                let newOptions = [] as google.maps.places.AutocompletePrediction[];
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            });
        }
    };

    return googleApiIsLoaded ? (
        <StyledAutocomplete
            {...rest}
            data-target-id="google-places-autocomplete"
            $heightVariant={heightVariant}
            disabled={disabled}
            getOptionLabel={(option: unknown) =>
                typeof option === 'string' ? option : (option as google.maps.places.AutocompletePrediction).description
            }
            filterOptions={(x) => x}
            options={options}
            value={selectedOption}
            noOptionsText={t('common:general.noResult')}
            onChange={(event: React.SyntheticEvent, item: unknown, reason: string) =>
                handleChange(item as google.maps.places.AutocompletePrediction | null, reason)
            }
            onInputChange={handleSearchInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...inputField}
                    label={label}
                    helperText={helperText}
                    placeholder={placeholder}
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Icon color={colours.primary.O87} name="Map" />,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ ...params.inputProps, ...InputProps?.inputProps }}
                    InputLabelProps={{
                        shrink: Boolean((params.inputProps as { value?: boolean } | undefined)?.value),
                        ...params.InputLabelProps,
                        ...InputLabelProps,
                    }}
                />
            )}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, _option: unknown) => {
                const option = _option as google.maps.places.AutocompletePrediction;
                const matches = option.structured_formatting?.main_text_matched_substrings ?? [];
                const parts = parse(
                    option.structured_formatting?.main_text,
                    matches.map((match: google.maps.places.PredictionSubstring) => [
                        match.offset,
                        match.offset + match.length,
                    ]),
                );
                return (
                    <Grid alignItems="center" container {...(props as GridProps)}>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    data-target="autocomplete-option"
                                    data-target-id={`autocomplete-option-${index}`}
                                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                                >
                                    {part.text}
                                </span>
                            ))}
                            <Typography>{option.structured_formatting?.secondary_text}</Typography>
                        </Grid>
                    </Grid>
                );
            }}
            autoComplete
            includeInputInList
            filterSelectedOptions
            blurOnSelect
        />
    ) : null;
};

export default GooglePlacesAutocomplete;
